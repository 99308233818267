import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import RecurrenceGenerator from "components/common/RecurrenceGenerator";
import RecurrencePortfolioGenerator from "components/common/RecurrencePortfolioGenerator";
import WorkticketStatusChip from "components/ui/Worktickets/WorkticketStatusChip";
import WorkticketSoloStatusChip from "components/ui/Worktickets/WorkticketSoloStatusChip";
import WorkticketRequestStatus from "components/ui/Worktickets/WorkticketRequestStatus";
import WorkticketOnSiteChip from "components/ui/Worktickets/WorkticketOnSiteChip";
import WorkticketComplianceChip from "components/ui/Worktickets/WorkticketComplianceChip";
import LoadingDialog from "components/ui/dialog/loadingDialog";
import RecurrenceIcon from "@material-ui/icons/Autorenew";
import {
  getWorkticketDetails,
  workticketUnlink,
} from "services/workticketService";
import {
  saveGenerator,
  updateGenerator,
  deleteGenerator,
} from "services/generatorService";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import {
  usePageHeaderState,
  usePageHeaderDispatch,
} from "contexts/pageHeaderContext";
import {
  convertDateFormatField,
  DaysFromNowStandard,
} from "components/util/timeFormat";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const WorkticketHeader = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [openRecurrence, setOpenRecurrence] = useState(false);
  const [openPortfolioRecurrence, setOpenPortfolioRecurrence] = useState(false);
  const [generator, setGenerator] = useState(null);
  const [isLoadingGenerator, setIsLoadingGenerator] = useState(false);
  const [stateContext, dispatchContext] = useWorkticketView();
  const dispatchPageHeader = usePageHeaderDispatch();
  const { pageHeaderTabs } = usePageHeaderState();
  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    if (!isLoading) {
      const childGenerator =
        workticket.generator && workticket.generator.length
          ? workticket.generator[0]
          : null;
      setGenerator(childGenerator);
    }
  }, [isLoading, workticket]);

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  const handleRecurrence = () => {
    setOpenRecurrence(!openRecurrence);
  };

  const handlePortfolioRecurrence = () => {
    setOpenPortfolioRecurrence(!openPortfolioRecurrence);
  };

  const handleRecurrenceSubmit = async (id, data) => {
    if (!id) {
      setIsLoadingGenerator(true);
      try {
        const resultGenerator = await saveGenerator({
          ...data,
          source_ids: [workticket.id],
        });
        setGenerator(resultGenerator.data.data.generators[0]);
        setIsLoadingGenerator(false);
      } catch (e) {
        logException(e, "Cannot create generator");
      }
    } else {
      try {
        await updateGenerator(id, {
          ...data,
        });
        setIsLoadingGenerator(false);
      } catch (e) {
        logException(e, "Cannot update generator");
      }

      checkRedirectList();
    }
  };

  const handleGeneratorDelete = async (id) => {
    if (id) {
      setIsLoadingGenerator(true);
      try {
        await deleteGenerator(id);
        setGenerator(null);
        setIsLoadingGenerator(false);
      } catch (e) {
        logException(e, "Cannot delete generator");
      }
      checkRedirectList();
    }
  };

  const handleRecurrenceUnlink = async () => {
    try {
      await workticketUnlink(workticket.id);
      workticket.instance_override = true;
      dispatchContext({
        type: "SET_WORKTICKET",
        workticket: workticket,
      });

      setIsLoadingGenerator(false);
    } catch (e) {
      logException(e, "Cannot unlink workticket");
    }
  };

  const checkRedirectList = async () => {
    const { history } = props;

    try {
      await getWorkticketDetails(workticket.id);
    } catch (e) {
      dispatchPageHeader({
        type: "SET_PAGE_TAB",
        pageHeaderTabs: [
          ...pageHeaderTabs.filter(
            (page) => page.link !== window.location.pathname
          ),
        ],
      });
      history.push(`/worktickets`);
    }
  };

  const LabelSubtitle = (props) => (
    <Typography
      variant="caption"
      className={classes.containerSubtitleItem}
      gutterBottom
    >
      {props.children}
    </Typography>
  );

  return (
    <>
      <Typography variant="h3" className={classes.titleBody} gutterBottom>
        {workticket.number}
        <Box className={classes.containerStatus}>
          <WorkticketStatusChip
            status={workticket.status}
            dueDate={workticket.start_date}
            archived={0}
            recalled={workticket.recalled}
            escalated={workticket.escalated}
            cancellation_reviewed={workticket.cancellation_reviewed}
          />
          {parseInt(workticket.status) !== 6 ? (
            <Chip
              label={`Due: ${convertDateFormatField(workticket.start_date)}`}
              className={classNames(classes.chipPrimary)}
            />
          ) : (
            <Box className={classes.requestStatusContainer}>
              <WorkticketRequestStatus
                status={workticket.status}
                dateCreated={workticket.external.call_date}
                timezone={workticket.job.timezone}
                isApproved={
                  parseInt(workticket.external.approval) === 1 ? true : false
                }
                category={workticket.workticket_category}
              />
            </Box>
          )}
          {(DaysFromNowStandard(workticket.start_date) > 1 ||
            workticket.type === 4 ||
            workticket.workticket_category === 4) &&
            workticket.status < 2 && (
              <WorkticketSoloStatusChip
                status={workticket.status}
                type={workticket.type}
                category={workticket.workticket_category}
              />
            )}
          {[2, 4, 5].includes(workticket.status) && workticket.recalled ? (
            <WorkticketStatusChip status={0} recalled={1} />
          ) : null}
          {!Number(workticket.compliance_checked) ? (
            <WorkticketOnSiteChip
              on_site={workticket?.geo?.geo_compliance}
              status={workticket.status}
              recalled={workticket.recalled}
            />
          ) : null}
          {([2, 4, 5].includes(workticket.status) &&
            workticket.compliance_checked) ||
          workticket.recalled ? (
            <WorkticketComplianceChip
              onSite={workticket.compliance_onsite}
              onTime={workticket.compliance_ontime}
              hasDocuments={workticket.compliance_document}
            />
          ) : null}
        </Box>
      </Typography>
      <Box>
        {workticket.external_start_date ? (
          <Chip
            label={`Start Date: ${convertDateFormatField(
              workticket.external_start_date
            )}`}
            className={classNames(classes.chipExternalPrimary)}
          />
        ) : null}
        {workticket.project && workticket.project.external_woid ? (
          <Chip
            label={`WOID: ${workticket.project.external_woid}`}
            className={classNames(classes.chipExternalPrimary)}
          />
        ) : workticket.external_woid ? (
          <Chip
            label={`WOID: ${workticket.external_woid}`}
            className={classNames(classes.chipExternalPrimary)}
          />
        ) : null}
        {workticket.preview ? (
          <Chip
            label="Preview"
            className={classNames(classes.chipExternalPrimary)}
          />
        ) : null}
      </Box>
      <Box className={classes.containerSubtitle}>
        {workticket.created_at && (
          <LabelSubtitle>
            Date Created: {convertDateFormatField(workticket.created_at)}
          </LabelSubtitle>
        )}
        {parseInt(workticket.status) === 6 ? (
          <LabelSubtitle>
            {`Due Date: ${convertDateFormatField(workticket.start_date)}`}
          </LabelSubtitle>
        ) : (
          <LabelSubtitle>
            Scheduled For:{" "}
            {workticket?.schedules[0]
              ? convertDateFormatField(workticket.schedules[0].schedule_date)
              : "None"}
          </LabelSubtitle>
        )}
        {workticket.sub_completed_date && (
          <LabelSubtitle>
            Mark as Completed:{" "}
            {convertDateFormatField(workticket.sub_completed_date)}
          </LabelSubtitle>
        )}
        {![0, 1, 4].includes(workticket.status) &&
          workticket.completed_date && (
            <LabelSubtitle>
              Date Verified: {convertDateFormatField(workticket.completed_date)}
            </LabelSubtitle>
          )}
        {workticket.recalled_date && (
          <LabelSubtitle>
            Date Recalled: {convertDateFormatField(workticket.recalled_date)}
          </LabelSubtitle>
        )}
      </Box>
      {workticket.workticket_category !== 4 &&
      hasPermission(permissionWorkticket.GENERATORS, permissions) ? (
        <Box className={classes.containerRecurrence}>
          {!workticket.recurrence_instance_id ? (
            <>
              {(workticket.workticket_category === 0 && !generator) ||
              generator ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  disableElevation
                  startIcon={<RecurrenceIcon />}
                  className={classNames(
                    classes.button,
                    generator
                      ? classes.buttonOutlinedSuccess
                      : classes.buttonOutlined
                  )}
                  onClick={handleRecurrence}
                >
                  {generator ? "Recurrence" : "Create Recurrence"}
                </Button>
              ) : null}
              <RecurrenceGenerator
                open={openRecurrence}
                type={0}
                subTitle={workticket.number}
                data={generator}
                handleClose={handleRecurrence}
                handleSubmit={handleRecurrenceSubmit}
                handleGeneratorDelete={handleGeneratorDelete}
              />
              <LoadingDialog open={isLoadingGenerator} />
            </>
          ) : !workticket.instance_override ? (
            <>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                disableElevation
                startIcon={<RecurrenceIcon />}
                className={classNames(
                  classes.button,
                  classes.buttonOutlinedSuccess
                )}
                onClick={handlePortfolioRecurrence}
              >
                Recurrence
              </Button>
              <RecurrencePortfolioGenerator
                open={openPortfolioRecurrence}
                subTitle={workticket.number}
                data={workticket.recurrence}
                serviceData={workticket?.preventative_service[0]}
                handleClose={handlePortfolioRecurrence}
                handleRecurrenceUnlink={handleRecurrenceUnlink}
              />
            </>
          ) : null}
        </Box>
      ) : null}
    </>
  );
};

export default withRouter(WorkticketHeader);
