import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import CommentIcon from "@material-ui/icons/QuestionAnswerOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { imageIcon } from "./icons";
import { documentIcon } from "./icons";
import { videoIcon } from "./icons";
import { chatIconSolid } from "./icons";
import { chatIconOutlined } from "./icons";
import { linkIcon } from "./icons";
import { PinIcon } from "./icons";
import VideoLinkPreviewComponent from "./videoLinkPreview";
import CommentList from "./commentList";
import TabComment from "./tabComment";
import AddCommentButton from "./addCommentButton";
import ConfirmationDialog from "./confirmationDialog";
import MessageDialog from "./dialog/messageDialog";
import FilePreviewDialog from "./filePreviewDialog";
import { dateFormatField } from "components/util/timeFormat";
import { logException } from "components/util/logUtil";
import { deleteFile, updateFile } from "services/fileService";
import { workticketPinningFile } from "services/workticketService";
import { workticketFiles } from "services/workticketService";
import { getCommentByFileId } from "services/fileService";
import { useWorkticketView } from "contexts/workticketViewContext";
import { mimeTypes } from "constants.js";
import * as classNames from "classnames";
import useStyles from "./styles";

const iconMap = {
  1: documentIcon(),
  2: imageIcon(),
  3: videoIcon(),
  4: linkIcon(),
  5: linkIcon(),
};

const getMenuOptions = (row) => {
  const options = [
    {
      id: 1,
      label: "Edit Name",
      value: "EDIT",
      icon: <EditIcon fontSize="inherit" />,
    },
  ];

  if (parseInt(row.is_pinned) === 0) {
    options.push({
      id: 2,
      label: "Pin to Top Section",
      value: "PIN",
      icon: PinIcon(),
    });
  }

  if (parseInt(row.is_pinned) === 1) {
    options.push({
      id: 3,
      label: "Unpin from Top Section",
      value: "UNPIN",
      icon: PinIcon(),
    });
  }

  options.push({
    id: 4,
    label: "Add Comment",
    value: "ADD_COMMENT",
    icon: <CommentIcon fontSize="inherit" />,
  });

  if (parseInt(row.mime_type) !== 4) {
    options.push({
      id: 5,
      label: "Download",
      value: "DOWNLOAD",
      icon: <DownloadIcon fontSize="inherit" />,
    });
  }

  options.push({
    id: 6,
    label: "Delete",
    value: "DELETE",
    icon: <DeleteIcon fontSize="inherit" />,
  });

  return options;
};

const defaultTab = "internal";
const columnNames = ["", "", "Name", "Owner", "Size", "Uploaded", ""];

const TableComponent = ({
  data,
  updateData,
  workTicketNumber,
  workTicketId,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [newName, setNewName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [openCollapsibleIndex, setOpenCollapsibleIndex] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openVideoLinkPreview, setOpenVideoLinkPreview] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [commentListData, setCommentListData] = useState([]);
  const [isLoadingCommentsList, setIsLoadingCommentsList] = useState(true);
  const [stateContext, dispatchContext] = useWorkticketView();
  const { refreshData, workticketFileComments, refreshToggleComments } =
    stateContext ?? null;

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const setWorkTicketFiles = (files) => {
    dispatchContext({
      type: "SET_WORKTICKET_FILES",
      workticketFiles: files,
    });
  };

  const fetchData = async () => {
    try {
      setIsLoadingData(true);
      const response = await workticketFiles(workTicketId);
      setWorkTicketFiles(response.data.data);
      dispatchContext({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
      dispatchContext({
        type: "SET_REFRESH_TOGGLE_COMMENTS",
        refreshData: false,
      });
      setIsLoadingData(false);
    } catch (error) {
      logException(error, "Error fetching workticket files");
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    if (refreshData || refreshToggleComments) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, refreshToggleComments]);

  useEffect(() => {
    if (workticketFileComments) {
      setCommentListData(workticketFileComments[activeTab]);
    }
  }, [workticketFileComments, activeTab]);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [openCollapsibleIndex]);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);

    if (openCollapsibleIndex !== null) {
      setOpenCollapsibleIndex(null);
    }
  };

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    setSelectedRow(null);
    if (option) {
      handleSelect(option);
    }
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    const actionHandlers = {
      EDIT: handleEditClick,
      ADD_COMMENT: handleAddCommentClick,
      DELETE: handleDeleteClick,
      DOWNLOAD: handleDownloadClick,
      PIN: () => handlePinOrUnPinFile("PIN"),
      UNPIN: () => handlePinOrUnPinFile("UNPIN"),
    };

    const selectedAction = actionHandlers[action];
    if (selectedAction) {
      selectedAction();
    }
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setOpenEditDialog(true);
    setSelectedRow(selectedRow);
    setNewName(selectedRow.display_name);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setNameError(false);
  };

  const handleConfirmEdit = async () => {
    if (!newName.trim()) {
      setNameError(true);
      return;
    }
    const fileExtension = selectedRow.display_name.split(".").pop();
    const baseName = newName.includes(".")
      ? newName.split(".").slice(0, -1).join(".")
      : newName;

    setIsLoadingData(true);
    setOpenEditDialog(false);
    setOpenMessage(true);
    const data = {
      display_name: `${baseName}.${fileExtension}`,
      description: "",
    };
    try {
      await updateFile(selectedRow.id, data);
      fetchData();
      setOpenMessageContent("File has been updated successfully.");
    } catch (e) {
      logException(e, "Cannot update file");
      setIsLoadingData(false);
    }
  };

  const handleDeleteClick = () => {
    setOpenDelete(true);
    setSelectedRow(selectedRow);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoadingData(true);
      setOpenDelete(false);
      setOpenMessage(true);
      await deleteFile(selectedRow.id);
      fetchData();
      setOpenMessageContent("File has been deleted successfully.");
    } catch (e) {
      logException(e, "Cannot remove file");
      setIsLoadingData(false);
    }
  };

  const handlePinOrUnPinFile = async (action) => {
    try {
      setIsLoadingData(true);
      setOpenMessage(true);
      await workticketPinningFile(workTicketId, selectedRow.id);
      fetchData();
      setOpenMessageContent(
        action === "PIN"
          ? "File has been pinning successfully."
          : "File has been unpinning successfully."
      );
    } catch (e) {
      logException(e, "Cannot update file");
      setIsLoadingData(false);
    }
  };

  const handleCommentsToggle = async (index, selectedRow) => {
    try {
      setIsLoadingCommentsList(true);
      setCommentListData([]);
      setOpenCollapsibleIndex(openCollapsibleIndex !== index ? index : null);
      setSelectedRow(openCollapsibleIndex !== index ? selectedRow : null);
      setCommentListData([]);
      const response = await getCommentByFileId(selectedRow.id);
      const dataComment = response.data.data;
      dispatchContext({
        type: "SET_WORKTICKET_FILE_COMMENTS",
        workticketFileComments: dataComment,
      });
      const firstKey = Object.keys(dataComment)[0];
      if (firstKey) {
        setActiveTab(firstKey);
      }
      setIsLoadingCommentsList(false);
    } catch (error) {
      logException(error, "Error fetching comments");
    }
  };

  const handleAddCommentClick = () => {
    handleCommentsToggle(data.indexOf(selectedRow), selectedRow);
  };

  const handleDownloadClick = () => {
    if (selectedRow) {
      window.open(selectedRow.file_url, "_blank");
    }
  };

  const handleCloseVideoLinkModal = () => {
    setOpenVideoLinkPreview(false);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderEmptyTableBody = () => {
    return (
      <>
        <TableRow className={classes.emptyTableRow}>
          {columnNames.map((columnName, index) => (
            <TableCell
              align="center"
              key={index}
              className={classNames(
                classes.emptyTableCells,
                classes.headerTableText,
                {
                  [classes.nameCell]: index === 2,
                }
              )}
            ></TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={columnNames.length}
            className={classes.noFilesCell}
          >
            <Typography variant="body1">No files uploaded yet.</Typography>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <TableContainer>
      <Table aria-label="simple table" className={classes.tableWorkticketFiles}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columnNames.map((name, index) => (
              <TableCell
                align="left"
                key={index}
                className={classNames(
                  classes.emptyTableCells,
                  classes.headerTableText,
                  {
                    [classes.nameCell]: index === 2,
                  }
                )}
              >
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length > 0
            ? data.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    className={classNames(
                      {
                        [classes.stripedRow]: index % 2 !== 0,
                      },
                      classes.borderRow
                    )}
                  >
                    <TableCell className={classes.imageCell}>
                      <IconButton onClick={() => handleThumbnailClick(index)}>
                        {iconMap[row.mime_type]}
                      </IconButton>
                    </TableCell>
                    <TableCell className={classes.pinningCell}>
                      {parseInt(row.is_pinned) === 1 && (
                        <Box className={classes.pushPinIcon}>{PinIcon()}</Box>
                      )}
                    </TableCell>
                    <TableCell className={classes.nameCell}>
                      {[mimeTypes.LINK, mimeTypes.LINK_360].includes(
                        parseInt(row.mime_type)
                      ) ? (
                        <Typography
                          variant="body1"
                          className={classes.linkStyle}
                          onClick={() => handleThumbnailClick(index)}
                        >
                          {row.display_name}
                        </Typography>
                      ) : (
                        row.display_name
                      )}
                    </TableCell>
                    <TableCell>{row.owner}</TableCell>
                    <TableCell className={classes.sizeCell}>
                      <nobr>{row.file_size ? row.file_size + " MB" : " "}</nobr>
                    </TableCell>
                    <TableCell className={classes.uploadedCell}>
                      {dateFormatField(row.created_at)}
                    </TableCell>
                    <TableCell className={classes.cellContent}>
                      <IconButton
                        onClick={() => handleCommentsToggle(index, row)}
                      >
                        {parseInt(row.has_comments) === 1
                          ? chatIconSolid()
                          : chatIconOutlined()}
                      </IconButton>
                      <IconButton onClick={(event) => handleClick(event, row)}>
                        <MoreHorizIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {openCollapsibleIndex === index && (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Box className={classes.commentToggleSection}>
                          <Box style={{ marginBottom: 16 }}>
                            <TabComment
                              onChangeTab={handleTabChange}
                              activeTab={activeTab}
                            />
                          </Box>
                          <Box>
                            <CommentList
                              data={commentListData}
                              file={selectedRow}
                              workTicketId={workTicketId}
                              activeTab={activeTab}
                              onTimeClick={() => {}}
                              onCommentChange={() => {}}
                              embed
                              isLoadingComments={isLoadingCommentsList}
                            />
                          </Box>
                        </Box>
                        <Box className={classes.addCommentToggleSection}>
                          <AddCommentButton
                            file={selectedRow}
                            workTicketId={workTicketId}
                            activeTab={activeTab}
                            onCommentChange={() => {}}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl) && selectedRow === row}
                    onClose={handleClose}
                  >
                    {getMenuOptions(row).map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={handleSelect}
                        data={option.value}
                      >
                        <Box className={classes.menuIcon}>{option.icon}</Box>
                        <Typography
                          variant="caption"
                          className={classes.menuText}
                        >
                          {option.label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </React.Fragment>
              ))
            : renderEmptyTableBody()}
        </TableBody>
      </Table>
      <ConfirmationDialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        onConfirm={handleConfirmEdit}
        title="Documents"
        body={
          <>
            <FormControl
              className={classes.formControl}
              fullWidth
              style={{ textAlign: "left" }}
            >
              <FormLabel
                className={classes.videoLinkLabelText}
                style={{ paddingTop: 0 }}
              >
                Name
              </FormLabel>
              <TextField
                className={classes.videoLinkFormText}
                variant="outlined"
                size="small"
                value={newName}
                error={nameError}
                helperText={nameError ? "Name is Required" : ""}
                FormHelperTextProps={{ style: { marginLeft: 0 } }}
                onChange={(e) => {
                  setNewName(e.target.value);
                  setNameError(false);
                }}
                inputProps={{ style: { fontSize: 14 } }}
              />
            </FormControl>
          </>
        }
      />
      <ConfirmationDialog
        open={openDelete}
        onClose={handleCloseDelete}
        onConfirm={handleConfirmDelete}
        title={"Documents"}
        content="Are you sure you want to remove the file?"
      />
      <VideoLinkPreviewComponent
        open={openVideoLinkPreview}
        handleClose={handleCloseVideoLinkModal}
        videoUrl={selectedRow ? selectedRow.file_url : ""}
        description={selectedRow ? selectedRow.description : ""}
        owner={selectedRow ? selectedRow.owner : ""}
        createdAt={selectedRow ? selectedRow.created_at : ""}
        displayName={selectedRow ? selectedRow.display_name : ""}
        workTicketNumber={workTicketNumber}
      />
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
      />
      {selectedImageIndex !== null && (
        <FilePreviewDialog
          open={openDialog}
          onClose={handleCloseDialog}
          classes={classes}
          files={data}
          workTicketNumber={workTicketNumber}
          workTicketId={workTicketId}
          currentImageIndex={selectedImageIndex}
        />
      )}
    </TableContainer>
  );
};
export default TableComponent;
