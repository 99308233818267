import React, { useState, useRef, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { attachFileIcon } from "./icons";
import { uploadFileChunk } from "services/workticketService";
import { useUploadFileDispatch } from "contexts/uploadFileContext";
import useStyles from "./styles";

const CHUNK_SIZE = 5 * 1024 * 1024;

const allowedTypes = [
  "image/jpeg",
  "image/png",
  "video/mp4",
  "application/pdf",
];

const classifyFiles = (files) => {
  const result = { all: [], pdf: [], image: [], video: [], links: [] };

  files.forEach((file, index) => {
    const fileInfo = {
      id: index,
      display_name: file.name,
      status: "In Progress",
      progress: 0,
      message: "",
    };

    classifyFileType(file, fileInfo, result);
    result.all.push(fileInfo);
  });

  return result;
};

const classifyFileType = (file, fileInfo, result) => {
  if (file.type.startsWith("image")) {
    fileInfo.mime_type = 2;
    result.image.push(fileInfo);
  } else if (file.type.startsWith("video")) {
    fileInfo.mime_type = 3;
    result.video.push(fileInfo);
  } else if (file.type === "application/pdf") {
    fileInfo.mime_type = 1;
    result.pdf.push(fileInfo);
  }
};

const initUploadFiles = (classifiedFiles, dispatch) => {
  dispatch({
    type: "SET_UPLOAD_FILES",
    uploadFiles: classifiedFiles,
  });

  dispatch({ type: "SET_LOADING", isUploadFile: true });
};

const updateProgress = (fileName, progressEvent, dispatch) => {
  dispatch({
    type: "UPDATE_UPLOAD_PROGRESS",
    fileName,
    progress: progressEvent,
  });
};

const updateFileId = (fileName, fileId, dispatch) => {
  dispatch({
    type: "UPDATE_UPLOAD_FILE_ID",
    fileName,
    fileId,
  });
};

const updateStatus = (fileName, newStatus, chunkIndex, dispatch) => {
  dispatch({
    type: "UPDATE_UPLOAD_STATUS",
    fileName,
    status: newStatus,
    message: `Chunk ${chunkIndex} failed after 3 retries`,
  });
};

const createFormData = (
  file,
  workTicketId,
  chunkIndex,
  totalChunks,
  retryCount
) => {
  const start = chunkIndex * CHUNK_SIZE;
  const end = Math.min(start + CHUNK_SIZE, file.size);
  const chunk = file.slice(start, end);

  const formData = new FormData();
  formData.append("file", chunk);
  formData.append("workTicketId", workTicketId);
  formData.append("fileName", file.name);
  formData.append("chunkIndex", chunkIndex);
  formData.append("totalChunks", totalChunks);
  formData.append("retryCount", retryCount);
  return formData;
};

const simulateProgressForSingleChunk = (fileName, uploadFileDispatch) => {
  const minProgressIncrement = 10;
  let currentProgress = 0;

  const updateProgressAndScheduleNext = () => {
    currentProgress += minProgressIncrement;
    updateProgress(fileName, currentProgress, uploadFileDispatch);

    if (currentProgress < 100) {
      setTimeout(updateProgressAndScheduleNext, 200);
    }
  };

  updateProgressAndScheduleNext();
};

const UploadFile = ({ workTicketId }) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const uploadFileDispatch = useUploadFileDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).filter((file) =>
      allowedTypes.includes(file.type)
    );
    sendFiles(files);
  };

  const sendFiles = async (files) => {
    setIsUploading(true);
    try {
      const classifiedFiles = classifyFiles(files);
      initUploadFiles(classifiedFiles, uploadFileDispatch);

      const uploadPromises = files.map((file) =>
        uploadFileInChunks(file, workTicketId)
      );

      await Promise.all(uploadPromises);

      if (isMounted.current) {
        setIsUploading(false);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      if (isMounted.current) {
        setIsUploading(false);
      }
    }
  };

  const uploadFileInChunks = async (file, workTicketId) => {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let currentChunk = 0;

    const uploadChunk = async (chunkIndex, retryCount = 0) => {
      const formData = createFormData(
        file,
        workTicketId,
        chunkIndex,
        totalChunks,
        retryCount
      );

      try {
        const response = await uploadFileChunk(workTicketId, formData);
        const uploadData = response.data.data;

        if (response.status !== 200) {
          throw new Error(
            `Chunk ${chunkIndex} failed to upload. Status: ${response.status}`
          );
        }

        if (totalChunks === 1) {
          simulateProgressForSingleChunk(file.name, uploadFileDispatch);
        } else {
          const progress = ((chunkIndex + 1) / totalChunks) * 100;
          updateProgress(file.name, progress, uploadFileDispatch);
        }

        if (uploadData.completed) {
          updateFileId(file.name, uploadData.file_id, uploadFileDispatch);
        }
      } catch (error) {
        console.error(
          `Error uploading chunk ${chunkIndex} of ${file.name}:`,
          error
        );

        if (retryCount < 3) {
          await uploadChunk(chunkIndex, retryCount + 1);
        } else {
          updateStatus(file.name, "Failed", chunkIndex, uploadFileDispatch);
          throw error;
        }
      }
    };

    while (currentChunk < totalChunks) {
      await uploadChunk(currentChunk);
      currentChunk++;
    }
  };

  return (
    <Box>
      <input
        type="file"
        multiple
        accept="image/*, video/*, .pdf"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />

      <Button
        className={classes.buttonConfirm}
        variant="outlined"
        startIcon={attachFileIcon()}
        onClick={() => fileInputRef.current.click()}
        disabled={isUploading}
      >
        <Typography variant="body1">Upload Files</Typography>
      </Button>
    </Box>
  );
};

export default UploadFile;
